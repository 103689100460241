export const constant = {
  WX_APPID: 'wx6a6cd5b0260f5a5b', //wxcdfef0cd0958879d  wx6a6cd5b0260f5a5b
  WX_APPNAME: '蓬蓬绣'
}


export const directives = (app) => {
  app.directive('debounce' , {
    // 自定义指令挂载时的处理逻辑
    // vue3中的生命周期方法，el:当前指令绑定的元素，binding:当前指令对象，binding.value:指令绑定的值
    // 当指令挂载到元素上时触发
    mounted(el, binding){
      // 判断指令绑定的值是否为函数，如果不是则直接返回
      if(typeof binding.value !== 'function') return
      // 初始化定时器为null
      el.timer = null
      // 定义事件处理函数
      el.handler = function() {
        // 如果定时器存在，则清除定时器
        if(el.timer) {
          clearTimeout(el.timer)
        }
        // 设置定时器，600毫秒后执行指令绑定的函数
        el.timer = setTimeout(() => {
          binding.value()
        }, 600)
      }
      // 为元素添加点击事件监听器
      el.addEventListener('click',el.handler)
    },
    // 自定义指令卸载前的处理逻辑
    // 当指令从元素上卸载时触发
    beforeUnmount(el, binding){
      // 如果定时器存在，则清除定时器
      if(el.timer){
          el.timer = null;
          clearTimeout(el.timer)
      }
      // 移除元素的点击事件监听器
      el.removeEventListener('click',el.handler)
    }
  })
}