<template lang="">
  <div class="chat-container" >
    <div class="sidebar">
      <div class="headimg">
        <img :src="`${imgUrl}/file/show?fileName=${userInfo.avatarUrl}`" @error="setDefaultImage" alt="">
      </div>
      <div class="name">{{ userInfo.userName }}</div>
      <div class="session">
        <div :class="[index === selectSessionIdx ? 'active' : '', `${item.key}-session`]" v-for="(item, index) in sessionData" :key="index" @click="handleSelectSession(item, index)">
          <div class="session-icon" :class="item.key" ></div>
          <div class="session-name">{{ item.name }}</div>
        </div>
        <!-- <div class="over-session">
          <div class="over session-icon"></div>
          <div class="session-name">结束会话</div>
        </div> -->
      </div>
    </div>
    <div class="main">
      <div class="chat-header">
        <div class="header">
          <div class="header-left">
            <div class="session">
              <div class="s-dot"></div>
              <div class="s-name">会话</div>
              <div class="s-icon">
                <i class="el-icon-close"></i>
              </div>  
            </div>
          </div>
          <div class="header-right">
            <el-link :underline="false" type="danger" icon="el-icon-switch-button" @click="onExitSession">退出</el-link>
          </div>
        </div>
        <div class="chat-header-search">
          <el-form ref="form" :inline="true" :model="form">
            <el-form-item label="客服姓名">
              <el-select v-model="form.nameNum" clearable placeholder="请选择客服" @change="onSelectServicer">
                <el-option
                  v-for="item in servicerList"
                  :key="item.userNum"
                  :label="item.userName"
                  :value="item.userNum">
                  <span style="float: left">{{ item.userName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.userNum }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="时间范围">
              <el-date-picker
                v-model="form.date"
                type="daterange"
                align="right"
                unlink-panels
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <div class="chat-header-btn-group">
                <el-button class="search-btn" type="primary" :disabled="disabled" @click="onSearch">查询</el-button>
                <el-button class="clear-btn" type="default" @click="onClear">清空</el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="chat-body manager">
        <div class="servicer-list">
          <div class="title">客服列表</div>
          <div class="servicer-ul">
            <div class="servicer-item" 
              :class="index === selectServicerIdx ? 'active' : ''" 
              v-for="(item, index) in servicerList" 
              :key="item.chatId" 
              @click="handleSelectServicer(item, index)">
              <div class="headimg">
                <img :src="`${imgUrl}/file/show?fileName=${item.avatarUrl}`" @error="setDefaultImage" alt="">
              </div>
              <div class="name">{{ item.userName}}</div>
            </div>
          </div>
        </div>
        <div class="user-list">
          <div class="title">{{ sessionType }}</div>
          <!-- @scroll="handleScroll" -->
          <div class="user-scroll" >
            <div class="user-item" 
              :class="index === selectUserIdx ? 'active' : ''" 
              v-for="(item, index) in userList" 
              :key="item.chatId" @click="handleSelectUser(item, index)">
              <div class="headimg" :class="sessionStatus != 2 && item.chatsContent?.status == 1 ? 'unread' : 'read' ">
                <img :src="`${imgUrl}/file/show?fileName=${item.userHead}`" @error="setDefaultImage" alt="">
              </div>
              <div class="info">
                <div class="name">{{ item.userName}}</div>
                <div class="content" v-if="item.chatsContent">
                  <span class="timeout" v-if="sessionStatus != 2 && item.chatsContent?.overFlag == 1">[超时未回复]</span>
                  {{ item.chatsContent?.type === '1' ? item.chatsContent?.content : '[图片]'}}
                </div>
              </div>
              <div class="time" v-if="item.chatsContent?.createTime.split(' ')[0].substring(8, 10) == new Date().getDate()">{{ item.chatsContent.createTime.split(' ')[1].substring(0, 5) }}</div>
              <div class="time" v-else>{{ item.chatsContent?.createTime.split(' ')[0] }}</div>
            </div>
          </div>
        </div>
        <div class="chat-box">
          <div class="title">
            <div class="user-name">{{ currentUser }}</div>
            <!-- <div class="over-session" v-if="chatData.length > 0 && currentUser && sessionStatus == 1 ">
              <el-link type="warning" :underline="false" @click="onOverSession">结束会话</el-link>
            </div> -->
          </div>
          <div class="chat-content" ref="chatContent">
            <div class="chat-item" :class="item.sender === 'sysUser' ? 'sysUser-chat-item' : 'user-chat-item'" v-for="(item, index) in chatData" :key="index">
              <div class="chat-headimg">
                <img :src="`${imgUrl}/file/show?fileName=${item.userHead}` " @error="setDefaultImage" v-if="item.sender === 'user'" alt="user">
                <img :src="`${imgUrl}/file/show?fileName=${userInfo.avatarUrl}`" @error="setDefaultImage" v-if="item.sender === 'sysUser'" alt="sysUser">
              </div>
              <div class="chat-content-text" v-if="item.messageType == '1' || item.type == '1'">{{ item.content }}</div>
              <div class="chat-content-text" v-else-if="item.messageType == '2' || item.type == '2'">
                <el-image style="width: 100px; height: 100px; border-radius: 5px" :preview-src-list="[ item.content ]" :src="item.content" fit="fill"></el-image>
              </div>
            </div>
            <div class="pj" v-if="currentUserInfo.pjxj && showPjxj">
              <div class="pj-title">客户评价</div>
              <el-rate v-model="pjxj" :max="5" disabled show-text></el-rate>
            </div>
          </div>
          <div class="chat-input">
            <el-input type="textarea" :disabled="this.sessionStatus == 2 || !inputDisabled" v-model="messageText" placeholder="请输入消息内容" @keyup.enter.native="onSend"></el-input>
            <el-popover
              class="emoji-popover"
              placement="top-start"
              width="440"
              height="200"
              trigger="hover">
              <div class="emoji-list">
                <div class="emoji-item" v-for="(item, index) in sinaEmojiList" :key="index" @click="onSelectEmoji(item)">
                  <img :src="item.url" alt="">
                </div>
              </div>
              <el-link class="emoji" :underline="false" type="info" slot="reference">
                <img class="icon" src="../../assets/image/icon_smile.png" alt="" >
              </el-link>
            </el-popover>
            <el-button type="primary" @click="onSend" :disabled="true">发送</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { urlConfig } from '@/http/urlconfig';
const Base64 = require('js-base64').Base64;
import sinaEmoji from '@/utils/sinaEmoji'
import defaultImage from '@/assets/image/default.png'
import { 
  queryAdviserUser, 
  queryChatRecord, 
  queryChat4Time, 
  chatsSession

} from "@/http/chat";

export default {
  data() {
    return {
      form: {
        nameNum: '',
        date: [],
      },
      sessionData: [{
        name: '当前会话',
        key: 'current'
      }, {
        name: '结束会话',
        key: 'over'
      }],
      pjxj: 0,
      countdown: 0,
      sessionTimer: null,
      countdownTimer: null,
      timer: null,
      //  1 管理员  2 普通客服
      userRole: '',
      chatId: '',
      srcList: [],
      servicerNum: '',
      norUserInfo: {},
      total: 0,
      pageNum: 1,
      pageSize: 1000,
      sessionStatus: 1,
      sessionType: '正在会话中',
      selectSessionIdx: 0,
      showPjxj: false,
      sendBtn: false,
      showEmojis: false,
      currentUserInfo: {},
      userInfo: {},
      chatData: [],
      // 正在加载中
      disabled: true,
      loading: false,
      loadFinish: false,
      inputDisabled: false,
      loadText: '正在加载中...',
      loadMore: false,
      overFlag: 0,
      messageText: '',
      currentUser: '',
      userPageNum: 1,
      userPageSize: 20,
      acceptPageNum: 1,
      acceptPageSize: 20,
      selectUserIdx: null,
      selectServicerIdx: null,
      moreRecored: false,
      userList: [],
      network: true,
      socketState: false,
      heartbeatTimer: null,
      sinaEmojiList: sinaEmoji,
      heartbeatDelay: 1000 * 30,
      recordList: [],
      overTotal: 0,
      currentTotal: 0,
      acceptTotal: 0,
      acceptList: [],
      servicerList: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      imgUrl: urlConfig.IMG_URL,
    }
  },
  sockets: {
    // 连接成功
    onopen() {
      console.log("socket success");
      // this.stopHeartbeat();
      // this.startHeartbeat();
    },
    // 接收消息
    onmessage(e) {
      // console.log(' 接受到消息 >>>>>>>>>>>>>>>>>>>> ' + Base64.decode(e.data));
      if (Base64.decode(e.data) === 'pong') {
        this.socketState = true
      } else {
        clearInterval(this.countdownTimer);
        this.countdownTimer = null;
        var data = JSON.parse(Base64.decode(e.data));
        if (data.userNum == this.currentUserInfo.userNum && this.sessionStatus == 1){
          this.chatData.push(data);
          // 当前消息消除新消息提示
          // this.handleChatsRead(data.chatId);
          this.$nextTick(() => {
            this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight;
          })
        }
        console.log(this.chatData);
      }
    },
    // 连接报错
    onerror(err) {
      console.error(err);
    },
    // 关闭连接
    onclose() {
      console.log("socket close");
    },
  },
  watch: {
    network(val){
      if (val){
        window.location.reload();
      }
    }
  },
  created() {
    setTimeout(() => {
      window.location.reload();
    }, 1000 * 60 * 60 * 10);
  },
  mounted() {
    // 检测断网
    window.addEventListener('offline', () => {
      this.network = false
    });

    window.addEventListener('online', () => {
      this.network = true
    });

    let userInfo = sessionStorage.getItem('userInfo');
    if (userInfo) {
      this.userInfo = JSON.parse(userInfo);
      if (this.userInfo.userName.length > 8){
        this.userInfo.userName = this.userInfo.userName.substring(0, 8) + '...'
      }
      this.userRole = this.userInfo.userRole;
    }
    this.queryServicerData();
    this.querySessionChatList(this.userInfo.userNum);
  },
  methods: {
    setDefaultImage(e) {
      e.target.src = defaultImage
    },
    /*
    async handleChatsRead(chatId){
      let params = {
        chatId: chatId
      }
      await chatsRead(params).then( (res) => {
        console.log(res)
      })
    },
    */
    async queryChat4Time(sysUserNum) {
      let params = {
        sysUserNum: sysUserNum,
        startDate: this.form.date?.[0],
        endDate: this.form.date?.[1]
      }
      await queryChat4Time(params).then((res) => {
        this.userPageNum = 1;
        this.userList = res.rows || []
        this.total = res.total || 0;
        this.sessionType = this.sessionStatus == 1 ? `正在会话中（${this.userList.length}）` : `今日已结束（${this.userList.length}）`;
      })
    },
    async queryServicerData(){
      await queryAdviserUser().then((res) => {
        this.servicerList = res.data;
      });
    },
    /*
    async queryOverChatList(sysUserNum){
      let params = {
        chatStatus: 2,
        sysUserNum: sysUserNum,
				pageNum: this.userPageNum,
				pageSize: this.userPageSize
      }
      await queryChatList(params).then((res) => {
        if (res.code === 200){
          this.userList = res.rows || [];
        }
      })
    },
    */
    async querySessionChatList(sysUserNum) {
      let params = {
				sysUserNum: sysUserNum,
			}
      this.loadFinish = false;
      await chatsSession(params).then((res) => {
        if (res.code === 200){
          console.log(res)
          this.userList = res.data || [];
          /*
          this.total = res.total;
          let total = Math.ceil(res.total / this.userPageSize);
          if (this.userPageNum === 1){
            this.userList = res.rows || [];
          } else if (res.total < this.userPageSize){
            this.userList = res.rows || [];
            this.loadFinish = true;
            console.log('加载完毕');
          } else if (this.userPageNum >= total){
            this.userList = [...this.userList, ...res.rows];
            this.loadFinish = true;
            console.log('加载完毕');
          } else {
            this.userList = [...this.userList, ...res.rows];
          }
          */
          this.sessionType = this.sessionStatus == 1 ? `正在会话中（${this.total}）` : `今日已结束（${this.total}）`;
  
          if (this.currentUserInfo?.userNum) {
            this.userList.forEach((e, index) => {
              if (e.userNum === this.currentUserInfo.userNum){
                this.selectUserIdx = index;
              }
            })
          }
        }
      });
    },
    /*
    async queryCurrentChat(){
      let params = {
				userNum: this.currentUserInfo.userNum,
			}
      await queryCurrentChat(params).then((res) => {
        if (res.code === 200){}
      })
    },
    */
    async queryChatRecord(item) {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        chatId: item.chatId,
      }
      await queryChatRecord(params).then((res) => {
        if (res.code === 200){
          let total = Math.ceil(res.total / this.pageSize);
          if (total === 1){
            this.chatData = res.rows || [];
            this.moreRecored = true;
          } else if (this.pageNum === 1 && total > 1){
            this.chatData = res.rows || [];
            this.moreRecored = false;
          } else if (this.pageNum >= total){
            this.chatData = [...this.chatData, ...res.rows];
            this.moreRecored = true;
          } else {
            this.chatData = [...this.chatData, ...res.rows];
            this.moreRecored = false;
          }
          console.log(item.pjxj)
          this.$nextTick(() => {
            this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight;
          })
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    handleSelectSession(item, idx) {
      this.chatData = [];
      this.userList = [];
      this.currentUser = '';
      this.currentUserInfo = {};
      this.selectServicerIdx = null;
      this.selectUserIdx = null;
      this.selectSessionIdx = idx
      this.userPageNum = 1;
      this.sendBtn = false;
      this.showPjxj = false;
      this.inputDisabled = false;

      if (item.key == 'current'){
        this.sessionStatus = 1;
        this.disabled = true;
        this.querySessionChatList(this.userInfo.userNum);
      } else if (item.key == 'over'){
        // this.sessionType = `今日已结束（${this.total}）`;
        this.sessionStatus = 2;
        this.disabled = false;
        this.queryChat4Time(this.userInfo.userNum)
      }

      // this.querySessionChatList(this.userInfo.userNum);

    },
    onSelectServicer(item){
      console.log(item);
    },
    handleSelectServicer(item, idx){
      this.chatData = [];
      this.currentUser = '';
      this.showPjxj = false;
      this.selectServicerIdx = idx;
      this.servicerNum = item.userNum;
      
      if (this.sessionStatus == 1){
        this.querySessionChatList(item.userNum);
      } else if (this.sessionStatus == 2){
        this.queryChat4Time(item.userNum)
      }

    },
    handleSelectUser(item, idx){
      this.pageNum = 1;
      this.userPageNum = 1;
      this.chatData = [];
      this.sendBtn = true;
      this.inputDisabled = true;
      this.showPjxj = true;
      this.selectServicerIdx = null;
      this.selectUserIdx = idx;
      this.chatId = item.chatId;
      this.pjxj = item.pjxj || 0;
      this.currentUserInfo = item;
      this.overFlag = item.chatsContent?.overFlag;
      this.currentUser = item.userName;
      
      this.queryChatRecord(item)
      // this.handleChatsRead(item.chatId)
    },
    onMoreRecord() {
      if (this.moreRecored){
        return
      }
      this.pageNum++;
      this.queryChatRecord(this.currentUserInfo)
    },
    
    onExitSession(){
      this.$confirm('是否退出当前系统？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.sendBtn = false;
        this.inputDisabled = false;
        clearInterval(this.sessionTimer)
        this.sessionTimer = null;
        this.chatData = [];
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userInfo');
        
        this.$router.replace('/login');
      }).catch(() => {
        this.$message.info('取消退出');
      });
    },
    onSearch() {
      this.chatData = [];
      this.currentUser = '';
      this.showPjxj = false;
      this.sessionStatus = 2;
      this.selectSessionIdx = 1;
      this.selectUserIdx = null;
      this.selectServicerIdx = null;
      this.queryChat4Time(this.form?.name);
    },
    onClear(){
      this.form = {
        name: '',
        date: []
      };
    },
    onSelectEmoji(item){
      // 管理端不能发送消息及表情
      if (true){
        return;
      }
      let sendMessage = JSON.stringify({
        userName: this.currentUserInfo.userName || '',
        userHead: this.currentUserInfo.userHead || '',
				userNum: this.currentUserInfo.userNum || '',
				content: item.url,
				chatId: this.currentUserInfo.chatId,
				messageType: 2,
				sysUserNum: this.userInfo.userNum || '',
        sysUserName: this.userInfo.userName || '',
        sysUserHead: this.userInfo.avatarUrl || '',
        sender: 'sysUser',
			})
      let toBase64 = Base64.encode(sendMessage)
      this.chatData.push(JSON.parse(sendMessage))
      this.$socket.send(toBase64)
      this.showEmojis = false;
    },
    onSend() {
      // 管理端不能发送消息及表情
      if (this.messageText.trim() === ''){
        this.$message.error('请输入消息内容');
        return;
      }

      if (!this.currentUserInfo.userNum){
        this.$message.error('请选择客户');
        return;
      }
      
      if (!this.network) {
        this.$message.error('请检查网络连接！');
        return;
      }
      /*
      let sendMessage = JSON.stringify({
				messageType: 1,
        sender: 'sysUser',
				content: this.messageText,
				sysUserNum: this.userInfo.userNum || '',
        sysUserName: this.userInfo.userName || '',
        sysUserHead: this.userInfo.avatarUrl || '',
        userName: this.currentUserInfo.userName || '',
        userHead: this.currentUserInfo.userHead || '',
				userNum: this.currentUserInfo.userNum || '',
				chatId: this.currentUserInfo.chatId,
			})
      let toBase64 = Base64.encode(sendMessage)
      if (this.$socket && this.$socket.readyState === 1) {
        console.log(' 消息发送成功 >>>> ' + this.$socket)
        this.chatData.push(JSON.parse(sendMessage))
        this.$socket.send(toBase64)
        this.$nextTick(() => {
          this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight;
        })
        this.messageText = '';
      } else {
        this.messageText = '';
        console.log('socket 未连接，重新连接')
      }
      */
    },
  },
  destroyed() {
    clearInterval(this.sessionTimer)
    clearInterval(this.heartbeatTimer)
    clearInterval(this.countdownTimer)
    this.countdownTimer = null;
    this.heartbeatTimer = null;
    this.sessionTimer = null;
  },
}
</script>
<style lang="less" scoped>
@import url('../../assets/css/common.css');
img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.chat-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
  background: #f5f5f5;
  .sidebar {
    width: 148px;
    height: 100%;
    padding-top: 38px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #FCF5F5;
    padding: 38px 24px 0;
    box-sizing: border-box;
    .headimg {
      width: 80px;
      height: 80px;
    }
    .name {
      font-size: 20px;
      margin-top: 18px;
      text-align: center;
    }
    .session {
      margin-top: 75px;
      .current-session {
        cursor: pointer;
        opacity: 0.5;
        margin-bottom: 60px;
        .session-icon {
          width: 58px;
          height: 58px;
          margin-bottom: 8px;
          background: url(../../assets/image/icon_1.png) no-repeat center;
          background-size: contain;
        }
        .session-name {
          font-size: 16px;
          color: #FF6A6A;
        }
        &:hover {
          opacity: 0.8;
        }
        &:active, &:focus {
          opacity: 0.6;
        }
        &.active {
          opacity: 1;
        }
      }
      .over-session {
        cursor: pointer;
        opacity: 0.5;
        .session-icon {
          width: 58px;
          height: 58px;
          margin-bottom: 8px;
          background: url(../../assets/image/icon_2.png) no-repeat center;
          background-size: contain;
        }
        .session-name {
          font-size: 16px;
          color: #FF6A6A;
        }
        &:hover {
          opacity: 0.8;
        }
        &:active, &:focus {
          opacity: 0.6;
        }
        &.active {
          opacity: 1;
        }
      }
    }
  }
  .main {
    width: calc(100vw - 148px);
    height: 100%;
    background: #FFF;
    .chat-header {
      .header {
        height: 60px;
        display: flex;
        padding: 0 24px;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.05);
        .header-left {
          .session {
            padding: 8px 16px;
            display: flex;
            cursor: pointer;
            border-radius: 50px;
            align-items: center;
            border: 1px solid #eee;
            .s-dot {
              width: 10px;
              height: 10px;
              border-radius: 10px;
              background: #FF6A6A;
            }
            .s-name {
              font-size: 18px;
              padding: 0 10px;
            }
            .s-icon {
              font-size: 18px;
            }
          }
        }
        .header-right {
          font-size: 16px;
        }
      }
      .chat-header-search {
        padding: 0 24px;
        background: #F0F1F5;
        .el-form {
          padding-top: 22px;
        }
        .chat-header-btn-group {
          .el-button {
            // cursor: pointer;
            padding: 8px 24px;
            border-radius: 18px;
            &.search-btn {
              color: #FFF;
              border: 1px solid #FF6767;
              background: linear-gradient( 307deg, #FF6767 0%, #FFA4A4 100%);
              &.is-disabled {
                opacity: 0.6;
              }
            }
            &.clear-btn {
              background: #fff;
              color: #FF6767;
            }
            &:hover {
              opacity: 0.8;
            }
            &:active, &:focus {
              opacity: 0.6;
            }
          }
        }
      }
    }
    .chat-body {
      display: flex;
      height: calc(100% - 60px);
      &.manager {
        height: calc(100vh - 174px);
      }
      .servicer-list {
        // width: 320px;
        flex: 0.8;
        height: 100%;
        border-right: 1px solid #ddd;
        .title {
          padding: 24px 15px;
          font-size: 18px;
        }
        .servicer-ul {
          height: calc(100% - 72px);
          overflow-y: auto;
          .servicer-item {
            display: flex;
            align-items: center;
            padding: 15px 15px;
            cursor: pointer;
            &.active {
              background: #F7F8FC;
            }
            &:hover {
              opacity: 0.4;
            }
          }
          .headimg {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin-right: 20px;
            
          }
          .name {
            flex: 1;
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
      .user-list {
        flex: 1;
        width: 460px;
        height: 100%;
        .user-scroll {
          height: calc(100% - 72px);
          overflow-y: auto;
        }
        .title {
          padding: 24px;
          font-size: 18px;
        }
        .user-item {
          display: flex;
          align-items: center;
          padding: 15px 20px;
          cursor: pointer;
          position: relative;
          &.active {
            background: #F7F8FC;
          }
          .headimg {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin-right: 20px;
            position: relative;
            &.unread::before {
              content: '';
              width: 14px;
              height: 14px;
              border-radius: 50px;
              background: #FF6A6A;
              position: absolute;
              top: 0;
              right: 0;
            }
          }
          .info {
            flex: 1;
            margin-right: 2px;
            .name {
              font-size: 18px;
              width: 120px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .content {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              color: #999;
              font-size: 16px;
              margin-top: 10px;
              .timeout {
                color: #F29016;
              }
            }
          }
          .time {
            color: #ccc;
            position: absolute;
            top: 22px;
            right: 12px;
          }
          .close-btn {
            display: none;
            position: absolute;
            bottom: 10px;
            right: 24px;
            font-size: 22px;
            z-index: 6;
          }
          &:hover {
            .close-btn {
              display: block;
            }
          }
        }
      }
      .chat-box {
        flex: 2;
        height: 100%;
        background: #F5F6FA;
        .title {
          height: 70px;
          display: flex;
          padding: 0 24px;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #DDDDDD;
        }
        .chat-content {
          height: calc(100% - 250px);
          overflow-y: auto;
          .chat-item {
            display: flex;
            align-items: center;
            padding: 15px 24px;
            .chat-headimg {
              width: 60px;
              height: 60px;
              border-radius: 50%;
              margin-right: 20px;
             }
            .chat-content-text {
              display: flex;
              max-width: 60%;
              padding: 14px 24px;
              font-size: 22px;
              // min-height: 60px;
              // line-height: 60px;
              border-radius: 12px;
              align-items: center;
              word-break: break-word;
              justify-content: center;
            }
            &.sysUser-chat-item {
              transform: scaleX(-1);
              .chat-headimg {
                transform: scaleX(-1);
              }
              .chat-content-text {
                color: #fff;
                transform: scaleX(-1);
                background: #FF7B7B;
              }
            }
            &.user-chat-item {
              justify-content: flex-start;
              .chat-content-text {
                color: #333;
                background: #fff;
              }
            }
          }
          .chat-tips {
            color: #999;
            padding: 8px 14px;
            text-align: center;
            margin-bottom: 10px;
          }
          .pj {
            padding: 15px;
            text-align: center;
          }
          .more-record {
            padding: 15px 0;
            text-align: center;
          }
        }
        .chat-input {
          height: 180px;
          padding: 24px;
          position: relative;
          box-sizing: border-box;
          border-top: 1px solid #DDDDDD;
          .el-textarea {
            width: 100%;
            height: 100%;
            :deep(textarea) {
              width: 100%;
              height: 100%;
              border: none;
              resize: none;
              font-size: 20px;
              background: transparent;
              font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
            }
          }
          .emoji {
            position: absolute;
            top: 8px;
            left: 8px;
            .icon {
              width: 24px;
              height: 24px;
              &:active {
                opacity: 0.8;
              }
            }
          }
          .el-button {
            position: absolute;
            right: 24px;
            bottom: 24px;
            background: #FF6A6A;
            border-radius: 10px;
            color: #fff;
            border: 1px solid #FF6A6A;
            font-size: 18px;
            &.is-disabled {
              opacity: 0.4;
            }
          }
        }
      }
      .chat-accept {
        flex: 1;
        width: 460px;
        height: 100%;
        .title {
          padding: 24px;
          font-size: 18px;
        }
        .accept-list {
          height: calc(100% - 72px);
          overflow-y: auto;
          .accept-item {
            display: flex;
            padding: 15px 24px;
            position: relative;
            align-items: center;
            &.active {
              background: #F7F8FC;
            }
            .headimg {
              width: 60px;
              height: 60px;
              border-radius: 50%;
              margin-right: 20px;
            }
            .info {
              flex: 1;
              margin-right: 40px;
            }
            .name {
              font-size: 18px;
              font-weight: 500;
              width: 120px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .content {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              color: #999;
              font-size: 16px;
              margin-top: 10px;
            }
            .time {
              color: #ccc;
              position: absolute;
              top: 14px;
              right: 24px;
            }
            .accept-btn {
              position: absolute;
              right: 24px;
              bottom: 16px;
              .btn {
                cursor: pointer;
                padding: 8px 24px;
                color: #FFF;
                border-radius: 18px;
                background: linear-gradient( 307deg, #FF6767 0%, #FFA4A4 100%);
                &:hover {
                  opacity: 0.8;
                }
                &:active, &:focus {
                  opacity: 0.6;
                }
              }
            }
          }
          .loading-text {
            padding: 10px 0;
            font-size: 16px;
            text-align: center;
          }
        }
      }
    }
  }
}
.emoji-list {
  width: 440px;
  height: 200px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  // background: #fbfbfb;
  // position: absolute;
  // bottom: 36px;
  // left: -6px;
  justify-content: space-evenly;
  .emoji-item {
    width: 35px;
    height: 35px;
    margin: 8px 6px;
    &:hover {
      opacity: 0.8;
    }
    &:active, &:focus {
      opacity: 0.6;
    }
  }
}
</style>