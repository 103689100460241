<template lang="">
  <div class="chat-container" >
    <div class="sidebar">
      <div class="headimg">
        <img :src="`${imgUrl}/file/show?fileName=${userInfo.avatarUrl}`" @error="setDefaultImage" alt="">
      </div>
      <div class="name">{{ userInfo.userName }}</div>
      <div class="session">
        <div :class="[index === selectSessionIdx ? 'active' : '', `${item.key}-session`]" v-for="(item, index) in sessionData" :key="index" @click="handleSelectSession(item, index)">
          <div class="session-icon" :class="item.key" ></div>
          <div class="session-name">{{ item.name }}</div>
        </div>
        <!-- <div class="over-session">
          <div class="over session-icon"></div>
          <div class="session-name">结束会话</div>
        </div> -->
      </div>
    </div>
    <div class="main">
      <div class="chat-header">
        <div class="header">
          <div class="header-left">
            <div class="session">
              <div class="s-dot"></div>
              <div class="s-name">会话</div>
              <div class="s-icon">
                <i class="el-icon-close"></i>
              </div>  
            </div>
            <!-- <div class="close-socket">
              <el-link @click="onSocketClose">关闭socket连接</el-link>
            </div> -->
          </div>
          <div class="header-right">
            <el-link :underline="false" type="danger" icon="el-icon-switch-button" @click="onExitSession">退出</el-link>
          </div>
        </div>
      </div>
      <div class="chat-body normal">
        <div class="user-list">
          <div class="title">{{ sessionType }}</div>
          <div class="user-scroll">
            <div class="user-item" 
              :class="index === selectUserIdx ? 'active' : ''" 
              v-for="(item, index) in userList" 
              :key="item.chatId" @click="handleSelectUser(item, index)">
              <div class="headimg" :class="sessionStatus != 2 && item.chatsContent?.status == 1 ? 'unread' : 'read' ">
                <img :src="`${imgUrl}/file/show?fileName=${item.userHead}`" @error="setDefaultImage" alt="">
              </div>
              <div class="info">
                <div class="name">{{ item.userName}}</div>
                <div class="content" v-if="item.chatsContent">
                  <span class="timeout" v-if="sessionStatus != 2 && item.chatsContent?.overFlag == 1">[超时未回复]</span>
                  {{ item.chatsContent?.type === '1' ? item.chatsContent?.content : '[图片]'}}
                </div>
              </div>
              <div class="time" v-if="item.chatsContent?.createTime.split(' ')[0].substring(8, 10) == new Date().getDate()">{{ item.chatsContent?.createTime.split(' ')[1].substring(0, 5) }}</div>
              <div class="time" v-else>{{ item.chatsContent?.createTime.split(' ')[0] }}</div>

              <!-- <div class="close-btn" @click.stop="handleClose(item)" v-if="sessionStatus == 1">
                <i class="el-icon-circle-close"></i>
              </div> -->
            </div>
          </div>
        </div>
        <div class="chat-box">
          <div class="title">
            <div class="user-name">{{ currentUser }}</div>
            <div class="over-session" v-if="chatData.length > 0 && currentUser && sessionStatus == 1 ">
              <el-link type="warning" :underline="false" @click="onOverSession">结束会话</el-link>
            </div>
          </div>
          <div class="chat-content" ref="chatContent">
            <div class="chat-item" :class="item.sender === 'sysUser' ? 'sysUser-chat-item' : 'user-chat-item'" v-for="(item, index) in chatData" :key="index">
              <div class="chat-headimg">
                <img :src="`${imgUrl}/file/show?fileName=${item.userHead}` " @error="setDefaultImage" v-if="item.sender === 'user'" alt="user">
                <img :src="`${imgUrl}/file/show?fileName=${userInfo.avatarUrl}`" @error="setDefaultImage" v-if="item.sender === 'sysUser'" alt="sysUser">
              </div>
              <div class="chat-content-text" v-if="item.messageType == '1' || item.type == '1'">{{ item.content }}</div>
              <div class="chat-content-text" v-else-if="item.messageType == '2' || item.type == '2'">
                <el-image style="width: 100px; height: 100px; border-radius: 5px" :preview-src-list="[ item.content ]" :src="item.content" fit="fill"></el-image>
              </div>
            </div>
          </div>
          <div class="chat-input">
            <!-- :disabled="this.sessionStatus == 2 || !inputDisabled" -->
            <el-input type="textarea"  v-model="messageText" placeholder="请输入消息内容" @keyup.enter.native="onSend"></el-input>
            <el-popover
              class="emoji-popover"
              placement="top-start"
              width="440"
              height="200"
              trigger="hover">
              <div class="emoji-list">
                <div class="emoji-item" v-for="(item, index) in sinaEmojiList" :key="index" @click="onSelectEmoji(item)">
                  <img :src="item.url" alt="">
                </div>
              </div>
              <el-link class="emoji" :underline="false" type="info" slot="reference">
                <img class="icon" src="../../assets/image/icon_smile.png" alt="" >
              </el-link>
            </el-popover>
            <el-button type="primary" @click="onSend" >发送</el-button>
            <!-- :disabled="!sendBtn || this.sessionStatus == 2" -->
          </div>
        </div>
        <div class="chat-accept" v-if="userRole == 2">
          <div class="title">
            待接受会话
            <span style="color: #ff6a6a;">（{{ acceptList.length }}）</span>
          </div>
          <div class="accept-list">
            <div class="accept-item" v-for="(item, index) in acceptList" :key="index">
              <div class="headimg">
                <img :src="`${imgUrl}/file/show?fileName=${item.userHead}`" @error="setDefaultImage" alt="">
              </div>
              <div class="info">
                <div class="name">{{ item.userName }}</div>
                <div class="content" v-if="item.chatsContent">{{ item.chatsContent?.type == '1' ? item.chatsContent?.content : '[图片]' }}</div>
              </div>
              <div class="time" v-if="item.chatsContent?.createTime.split(' ')[0].substring(8, 10) == new Date().getDate()">{{ item.chatsContent?.createTime.split(' ')[1].substring(0, 5) }}</div>
              <div class="time" v-else>{{ item.chatsContent?.createTime.split(' ')[0] }}</div>
              <div class="accept-btn">
                <div class="btn" @click="onAccept(item)">接受</div>
              </div>
            </div>
            <div v-if="loading" class="loading-text">
              <el-icon class="el-icon-loading"></el-icon>
              {{ loadText }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { urlConfig } from '@/http/urlconfig';
const Base64 = require('js-base64').Base64;
import sinaEmoji from '@/utils/sinaEmoji'
import defaultImage from '@/assets/image/default.png'
import { 
  queryChatRecord, 
  queryChat4Time, 
  servicerReception, 
  chatsRead, 
  chatsBreak,
  chatsOver,
  chatsSession,
  chatsWaitAccept,
} from "@/http/chat";

export default {
  data() {
    return {
      form: {
        nameNum: '',
        date: [],
      },
      sessionData: [{
        name: '当前会话',
        key: 'current'
      }, {
        name: '结束会话',
        key: 'over'
      }],
      countdown: 0,
      countdownData: {},
      sessionTimer: null,
      countdownTimer: null,
      countdownTimerData: {},
      timer: null,
      //  1 管理员  2 普通客服
      userRole: '',
      chatId: '',
      srcList: [],
      servicerNum: '',
      norUserInfo: {},
      overTotal: 0,
      currentTotal: 0,
      acceptTotal: 0,
      total: 0,
      pageNum: 1,
      pageSize: 1000,
      sessionStatus: 1,
      sessionType: '正在会话中',
      selectSessionIdx: 0,
      sendBtn: false,
      manualClose: false,
      showEmojis: false,
      acceptUserInfo: {},
      currentUserInfo: {},
      userInfo: {},
      chatData: [],
      // 正在加载中
      loading: false,
      loadFinish: false,
      inputDisabled: false,
      loadText: '正在加载中...',
      loadMore: false,
      overFlag: 0,
      messageText: '',
      currentUser: '',
      userPageNum: 1,
      userPageSize: 100,
      acceptPageNum: 1,
      acceptPageSize: 100,
      selectUserIdx: null,
      selectServicerIdx: null,
      moreRecored: false,
      userList: [],
      network: true,
      socketState: false,
      heartbeatTimer: null,
      sinaEmojiList: sinaEmoji,
      heartbeatDelay: 1000 * 30,
      recordList: [],
      acceptList: [],
      servicerList: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      imgUrl: urlConfig.IMG_URL,
    }
  },
  sockets: {
    // 连接成功
    onopen() {
      console.log("socket success");
      this.startHeartbeat();
    },
    // 接收消息
    onmessage(e) {
      console.log(' 接受到消息 >>>>>>>>>>>>>>>>>>>> ' + Base64.decode(e.data));
      if (Base64.decode(e.data) === 'pong') {
        this.socketState = true
      } else {
        var data = JSON.parse(Base64.decode(e.data));
        if (data.userNum == this.currentUserInfo.userNum && this.sessionStatus == 1){
          clearInterval(this.countdownTimerData[`CHAT_${this.currentUserInfo.chatId}_TIMER`]);
          this.countdownTimerData[`CHAT_${this.currentUserInfo.chatId}_TIMER`] = null;
          this.chatData.push(data);
          // 当前消息消除新消息提示
          this.handleChatsRead(data.chatId);
          this.$nextTick(() => {
            this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight;
          })
        } else if (data.sessionType == '6'){
          this.$message.warning(data.content);
          this.queryChatRecord(this.currentUserInfo)
        }
      }
    },
    // 连接报错
    onerror(err) {
      console.error(err);
    },
    // 关闭连接
    onclose() {
      console.log("socket close");
      if (!this.manualClose){
        console.log("websocket 连接断开，重新连接");
        this.reconnection()
      }
    },
  },
  created() {
    setTimeout(() => {
      window.location.reload();
    }, 1000 * 60 * 60 * 10);

    this.onOverSession = this.debounce(this.handleSessionOver, 500);
  },
  mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    window.addEventListener('unload', this.handleUnload);

    let userInfo = sessionStorage.getItem('userInfo');
    if (userInfo) {
      this.userInfo = JSON.parse(userInfo);
      if (this.userInfo.userName.length > 8){
        this.userInfo.userName = this.userInfo.userName.substring(0, 8) + '...'
      }
      this.userRole = this.userInfo.userRole;
    }
    this.socketConnect();
    this.querySessionChatList(this.userInfo.userNum);
    this.queryAccepteList();
    clearInterval(this.sessionTimer);
    this.sessionTimer = setInterval(() => {
      if (this.sessionStatus == 1 && this.userRole == 2){
        this.acceptPageNum = 1
        this.querySessionChatList(this.userInfo.userNum);
        this.queryAccepteList();
      }
    }, 1000 * 2);
  },
  methods: {
    onSocketClose(){
      this.manualClose = true;
      this.$socket.close('1000', '手动关闭');
      this.$message.success('socket 连接已关闭！');

      this.reconnection()
    },
    // 启动socket心跳
		startHeartbeat() {
      this.stopHeartbeat();
      this.sendHeartbeat();
		  this.heartbeatTimer = setInterval(() => {
		    this.sendHeartbeat();
		  }, this.heartbeatDelay);
		},
		// 停止心跳
		stopHeartbeat(){
      clearInterval(this.heartbeatTimer);
      this.heartbeatTimer = null;
		},
    sendHeartbeat(){
			this.$socket.send('ping');
      this.socketState = false;
		},
    reconnection(){
      setTimeout(() => {
        this.$message.success('重新连接...');
        this.socketConnect();
      }, 1000 * 6);
    },
    socketSendMessage(item, msg){
      if (this.$socket && this.$socket?.readyState === 1){
        let sendMessage = JSON.stringify({
          content: msg,
          messageType: 1,
          userNum: item.userNum || this.currentUserInfo.userNum || '',
          userName: item.userName || this.currentUserInfo.userName || '',
          userHead: item.userHead || this.currentUserInfo.userHead || '',
          chatId: item.chatId || this.currentUserInfo.chatId || '',
          sysUserNum: this.userInfo.userNum || '',
          sysUserName: this.userInfo.userName || '',
          sysUserHead: this.userInfo.avatarUrl || '',
          sender: 'sysUser',
        })
        console.log(sendMessage)
        // let toBase64 = Base64.encode(sendMessage)
        this.chatData.push(JSON.parse(sendMessage))
        console.log(Base64.encode(sendMessage));
        this.$socket.send(Base64.encode(sendMessage))
      } else {
        console.log('socket 未连接，重新连接')
        this.reconnection()
      }
    },
    handleClose(item) {
      this.$confirm('是否关闭当前会话？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.sessionOver(item);
      }).catch(() => {
        console.log('已取消操作')
      });
      
    },
    debounce(func, wait) {
      let timeout;
      return function(...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
      };
    },
    async sessionOver(item){
      // this.socketSendMessage(item, '当前会话已结束!');
      let params = {
        chatId: item.chatId
      }
      await chatsOver(params).then( res => {
        if (res.code === 200){
          this.sendBtn = false;
          this.querySessionChatList(this.userInfo.userNum);
          setTimeout(() => {
            if (this.userList.length > 0 && item.chatId != this.currentUserInfo.chatId){
              this.handleSelectUser(this.currentUserInfo, this.selectUserIdx)
            } else {
              this.chatData = [];
              this.sendBtn = false;
              this.inputDisabled = false;
              this.currentUser = '';
              this.currentUserInfo = {};
              this.selectUserIdx = null;
              this.querySessionChatList(this.userInfo.userNum);
            }
          }, 600);
        }
      })
    },
    async sessionBreak(item){
      let params = {
        chatId: item.chatId,
        userNum: item.userNum,
      }

      await chatsBreak(params).then( (res) => {
        console.log(res)
        if (res.code === 200){
          this.$message.warning('已提醒用户长时间未回复，将在3分钟后断开！');
          // 3分钟后自动结束会话
          this.countdownData[`CHAT_${item.chatId}`] = 60 * 3;

          clearInterval(this.countdownTimerData[`CHAT_${item.chatId}_TIMER`]);
          this.countdownTimerData[`CHAT_${item.chatId}_TIMER`] = null;
          console.log(this.countdownTimerData)
          this.countdownTimerData[`CHAT_${item.chatId}_TIMER`] = setInterval(() => {
            this.countdownData[`CHAT_${item.chatId}`]--;
            console.log(this.countdownData[`CHAT_${item.chatId}`])
            if (this.countdownData[`CHAT_${item.chatId}`] === 0){
              this.countdownData[`CHAT_${item.chatId}`] = 0;
              this.sessionOver(item);
              clearInterval(this.countdownTimerData[`CHAT_${item.chatId}_TIMER`]);
              this.countdownTimerData[`CHAT_${item.chatId}_TIMER`] = null;
            }
          }, 1000);
          /*
          this.countdownTimer = setInterval(() => {
            
          }, 1000);
          */
          this.$nextTick(() => {
            this.$refs.chatContent.scrollTop = this.chatData.length * 180;
          })
        } else {
          this.$message.warning(res.msg);
        }
      })
    },
    setDefaultImage(e) {
      e.target.src = defaultImage
    },
    handleScroll(event){
      const { className, scrollTop, scrollHeight, clientHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight - 10 && !this.loadFinish) {
        console.info('翻页')
        this.loadFinish = true;
        setTimeout(() => {
          if (className === 'user-scroll'){
            this.userPageNum++;
            this.querySessionChatList(this.userInfo.userNum);
          } else if (className === 'accept-list'){
            this.acceptPageNum++;
            this.queryAccepteList();
          }
        }, 400);
      }
    },
    queryAccepteList(){
      this.loadFinish = false;
      chatsWaitAccept().then((res) => {
        // console.log(res)
        if (res.code === 200){
          this.acceptList = res.data || [];
          /*
          if (this.acceptPageNum === 1){
            this.acceptList = res.rows || [];
            this.loading = false;
          } else if (this.acceptPageNum >= Math.ceil(res.total / this.acceptPageSize)){
            this.acceptList = [...this.acceptList, ...res.rows];
            this.loading = false;
            this.loadFinish = true;
            console.log('加载完毕');
            return;
          } else {
            this.acceptList = [...this.acceptList, ...res.rows];
            this.loading = true;
          }

          this.acceptList.forEach((e, index) => {
            this.userList.forEach((u) => {
              if (u.chatId === e.chatId){
                this.acceptList.splice(index, 1);
              }
            })
            if (e.chatId == this.acceptUserInfo?.chatId){
              this.acceptList.splice(index, 1);
            }
          })
          */
        }

      });
    },
    async handleChatsRead(chatId){
      let params = {
        chatId: chatId
      }
      await chatsRead(params).then( (res) => {
        console.log(res)
      })
    },
    async queryChat4Time() {
      let params = {
        sysUserNum: this.userInfo.userNum,
      }
      await queryChat4Time(params).then((res) => {
        // this.sessionStatus = 2;
        // this.selectSessionIdx = 1
        this.userPageNum = 1;
        this.userList = res.rows || []
        this.sessionType = this.sessionStatus == 1 ? `正在会话中（${this.userList.length}）` : `今日已结束（${this.userList.length}）`;
      })
    },
    async querySessionChatList(sysUserNum) {
      let params = {
				sysUserNum: sysUserNum,
			}
      this.loadFinish = false;
      await chatsSession(params).then((res) => {
        if (res.code === 200){
          this.userList = res.data || [];
          /*
          // console.log(res)
          this.total = res.total;
          let total = Math.ceil(res.total / this.userPageSize);
          if (this.userPageNum === 1){
            this.userList = res.rows || [];
          } else if (res.total < this.userPageSize){
            this.userList = res.rows || [];
            this.loadFinish = true;
            console.log('加载完毕');
          } else if (this.userPageNum >= total){
            this.userList = [...this.userList, ...res.rows];
            this.loadFinish = true;
            console.log('加载完毕');
          } else {
            this.userList = [...this.userList, ...res.rows];
          }
          */
          this.sessionType = this.sessionStatus == 1 ? `正在会话中（${this.userList.length}）` : `今日已结束（${this.userList.length}）`;
  
          if (this.currentUserInfo?.userNum) {
            this.userList.forEach((e, index) => {
              if (e.userNum === this.currentUserInfo.userNum){
                this.selectUserIdx = index;
              }
            })
          }
          if (this.userList.length == 0){
            this.chatData = [];
            this.sendBtn = false;
            this.inputDisabled = false;
            this.currentUser = '';
            this.currentUserInfo = {};
            this.selectUserIdx = null;
          }
        }
      });
    },
    async servicerReception(item){
      let params = {
        chatId: item.chatId,
        userNum: this.userInfo.userNum || "",
        avatar: this.userInfo.avatarUrl || "",
        nickName: this.userInfo.userName || "",
      }

      await servicerReception(params).then((res) => {
        // console.log(res);
        if (res.code === 200){
          this.querySessionChatList(this.userInfo.userNum);
          this.queryAccepteList();
        }
      })
    },
    /*
    async queryCurrentChat(){
      let params = {
				userNum: this.currentUserInfo.userNum,
			}
      await queryCurrentChat(params).then((res) => {
        // console.log(res)
        if (res.code === 200){}

      })
    },
    */
    async queryChatRecord(item) {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        chatId: item.chatId,
      }
      await queryChatRecord(params).then((res) => {
        if (res.code === 200){
          let total = Math.ceil(res.total / this.pageSize);
          if (total === 1){
            this.chatData = res.rows || [];
            this.moreRecored = true;
          } else if (this.pageNum === 1 && total > 1){
            this.chatData = res.rows || [];
            this.moreRecored = false;
          } else if (this.pageNum >= total){
            this.chatData = [...this.chatData, ...res.rows];
            this.moreRecored = true;
          } else {
            this.chatData = [...this.chatData, ...res.rows];
            this.moreRecored = false;
          }
          this.$nextTick(() => {
            this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight;
          })
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    handleSelectSession(item, idx) {
      this.chatData = [];
      this.userList = [];
      this.currentUser = '';
      this.currentUserInfo = {};
      this.selectUserIdx = null;
      this.selectSessionIdx = idx
      this.userPageNum = 1;
      this.sendBtn = false;
      this.inputDisabled = false;
      
      if (item.key == 'current'){
        this.sessionStatus = 1;
        this.querySessionChatList(this.userInfo.userNum);
      } else if (item.key == 'over'){
        this.sessionStatus = 2;
        this.queryChat4Time()
      }

      this.querySessionChatList(this.userInfo.userNum);

    },
    onSelectServicer(item){
      console.log(item);
    },
    handleSelectServicer(item, idx){
      console.log(item);
      this.chatData = [];
      this.currentUser = '';
      this.selectServicerIdx = idx;
      this.servicerNum = item.userNum;
      this.querySessionChatList(item.userNum);
    },
    handleSelectUser(item, idx){
      this.pageNum = 1;
      this.userPageNum = 1;
      this.chatData = [];
      this.sendBtn = true;
      this.inputDisabled = true;
      // this.userList = [];
      this.selectServicerIdx = null;
      this.selectUserIdx = idx;
      this.chatId = item.chatId;
      this.currentUserInfo = item;
      this.overFlag = item.chatsContent?.overFlag;
      this.currentUser = item.userName;
      
      this.queryChatRecord(item)
      this.handleChatsRead(item.chatId)
    },
    onAccept(item){
      // this.currentUserInfo = {};
      // this.selectUserIdx = null;
      // console.log(item.chatsContent)
      this.userPageNum = 1;
      this.acceptPageNum = 1;
      this.sessionStatus == 1;
      this.selectSessionIdx = 0;
      this.chatId = item.chatId;
      this.acceptUserInfo = item;
      this.servicerReception(item)
      // this.reconnection()

      this.querySessionChatList(this.userInfo.userNum);
      this.queryAccepteList();

    },
    onMoreRecord() {
      if (this.moreRecored){
        return
      }
      console.log('查看更多记录');
      this.pageNum++;
      this.queryChatRecord(this.currentUserInfo)
    },
    socketConnect(){
			let appId = 'orangekey20240506';
			let keyId = 'b3Jhbmdla2V5MjAyNDA1MDY=';
			let timestamp = new Date().toISOString();
			
			let baseString = `${appId},${keyId},${timestamp}`;
			let signature = baseString + ',' + Base64.encode(encodeURIComponent(baseString))
			this.initWebSocket(signature);
		},
    initWebSocket(signature) {
      // let wsUrl = `ws://8.129.223.241:7070/websocket`;
      /*
      ${this.userInfo.userNum}
      ${this.userInfo.avatarUrl}
      ${this.userInfo.userName}
      */
			// let wst = `chatId=${this.chatId}&monitorSide=1&user_num=${this.currentUserInfo.userNum}&user_head=${encodeURIComponent(this.currentUserInfo.userHead)}&user_name=${this.currentUserInfo.userName}&sys_user_num=${this.userInfo.userNum}&sys_user_head=${encodeURIComponent(this.userInfo.avatarUrl)}&sys_user_name=${this.userInfo.userName}&authString=${signature}`;
      let wst = `monitorSide=1&authString=${signature}&user_num=${this.currentUserInfo.userNum}&sys_user_num=${this.userInfo.userNum}`;
      this.$connect(
        urlConfig.SOCKET_URL + '?' + wst, 
        { format: 'json' }
      )
    },
    handleSessionOver() {
      console.log('结束会话');
      var currentInfo = this.currentUserInfo;
      // console.log(currentInfo)
      if (this.userList[this.selectUserIdx].chatsContent.overFlag != 1){
        this.$message.warning('用户正常聊天中，请勿操作！');
        return;
      } 
      if (this.$socket?.readyState === 1){
        // 您已长时间未回复，将在3分钟后断开
        this.sessionBreak(currentInfo);
      } else {
        this.reconnection()
        setTimeout(() => {
          this.sessionBreak(currentInfo);
        }, 1000 * 10);
      }
    },
    onExitSession(){
      this.$confirm('是否退出当前系统？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.sendBtn = false;
        this.inputDisabled = false;
        this.stopHeartbeat();
        clearInterval(this.sessionTimer)
        this.sessionTimer = null;
        this.chatData = [];
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userInfo');
        this.manualClose = true;
        this.$socket?.close('1000', '退出系统');
        this.$router.push('/login');
      }).catch(() => {
        console.log('已取消操作')
      });
    },
    onClear(){
      this.form = {
        name: '',
        date: []
      };
    },
    onSelectEmoji(item){
      if (!this.sendBtn){
        return;
      }
      let sendMessage = JSON.stringify({
        userName: this.currentUserInfo.userName || '',
        userHead: this.currentUserInfo.userHead || '',
				userNum: this.currentUserInfo.userNum || '',
				content: item.url,
				chatId: this.currentUserInfo.chatId,
				messageType: 2,
				sysUserNum: this.userInfo.userNum || '',
        sysUserName: this.userInfo.userName || '',
        sysUserHead: this.userInfo.avatarUrl || '',
        sender: 'sysUser',
			})
      let toBase64 = Base64.encode(sendMessage)
      this.chatData.push(JSON.parse(sendMessage))
      this.$socket.send(toBase64)
      this.showEmojis = false;
    },
    onSend() {
      if (this.messageText.trim() === ''){
        this.$message.error('请输入消息内容');
        return;
      }

      if (!this.currentUserInfo.userNum){
        this.$message.error('请选择客户');
        return;
      }
      
      if (!this.network) {
        this.$message.error('请检查网络连接！');
        return;
      }
      let sendMessage = JSON.stringify({
        messageType: 1,
        sender: 'sysUser',
        content: this.messageText,
        sysUserNum: this.userInfo.userNum || '',
        sysUserName: this.userInfo.userName || '',
        sysUserHead: this.userInfo.avatarUrl || '',
        userName: this.currentUserInfo.userName || '',
        userHead: this.currentUserInfo.userHead || '',
        userNum: this.currentUserInfo.userNum || '',
        chatId: this.currentUserInfo.chatId,
      })
      console.log(sendMessage)
      let toBase64 = Base64.encode(sendMessage)
      //  && this.socketState
      if (this.$socket && this.$socket.readyState === 1) {
        this.chatData.push(JSON.parse(sendMessage))
        this.$socket.send(toBase64)
        console.log(' 消息发送成功 >>>> ', this.$socket)
        this.$nextTick(() => {
          this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight;
        })
        this.messageText = '';
      } else {
        this.messageText = '';
        console.log('socket 未连接，重新连接')
        this.$message.error('网络连接波动，请稍后 1s 重新发送消息');
        this.reconnection()
      }
    },
    handleBeforeUnload() {
      this.manualClose = true;
      // 在页面即将被卸载前触发，可以在这里添加需要执行的逻辑
      this.$socket?.close('1000', '页面刷新')
      console.log('页面即将被卸载')
    },
    handleUnload() {
      this.manualClose = true;
      // 在页面已经被卸载时触发，可以在这里添加需要执行的逻辑
      this.$socket?.close('1000', '页面刷新')
      console.log('页面已经被卸载')
    }
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    window.removeEventListener('unload', this.handleUnload);
  },
  destroyed() {
    this.stopHeartbeat();
    clearInterval(this.sessionTimer)
    this.sessionTimer = null;
    if (this.$socket?.readyState === 1){
      this.manualClose = true;
      this.$socket.close('1000', '页面销毁')
    }
  },
}
</script>
<style lang="less" scoped>
@import url('../../assets/css/common.css');
img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.chat-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
  background: #f5f5f5;
  .sidebar {
    width: 148px;
    height: 100%;
    padding-top: 38px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #FCF5F5;
    padding: 38px 24px 0;
    box-sizing: border-box;
    .headimg {
      width: 80px;
      height: 80px;
    }
    .name {
      font-size: 20px;
      margin-top: 18px;
      text-align: center;
      &.long-name {

      }
    }
    .session {
      margin-top: 75px;
      .current-session {
        cursor: pointer;
        opacity: 0.5;
        margin-bottom: 60px;
        .session-icon {
          width: 58px;
          height: 58px;
          margin-bottom: 8px;
          background: url(../../assets/image/icon_1.png) no-repeat center;
          background-size: contain;
        }
        .session-name {
          font-size: 16px;
          color: #FF6A6A;
        }
        &:hover {
          opacity: 0.8;
        }
        &:active, &:focus {
          opacity: 0.6;
        }
        &.active {
          opacity: 1;
        }
      }
      .over-session {
        cursor: pointer;
        opacity: 0.5;
        .session-icon {
          width: 58px;
          height: 58px;
          margin-bottom: 8px;
          background: url(../../assets/image/icon_2.png) no-repeat center;
          background-size: contain;
        }
        .session-name {
          font-size: 16px;
          color: #FF6A6A;
        }
        &:hover {
          opacity: 0.8;
        }
        &:active, &:focus {
          opacity: 0.6;
        }
        &.active {
          opacity: 1;
        }
      }
    }
  }
  .main {
    width: calc(100vw - 148px);
    height: 100%;
    background: #FFF;
    .chat-header {
      .header {
        height: 60px;
        display: flex;
        padding: 0 24px;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.05);
        .header-left {
          .session {
            padding: 8px 16px;
            display: flex;
            cursor: pointer;
            border-radius: 50px;
            align-items: center;
            border: 1px solid #eee;
            .s-dot {
              width: 10px;
              height: 10px;
              border-radius: 10px;
              background: #FF6A6A;
            }
            .s-name {
              font-size: 18px;
              padding: 0 10px;
            }
            .s-icon {
              font-size: 18px;
            }
          }
        }
        .header-right {
          font-size: 16px;
        }
      }
      .chat-header-search {
        padding: 0 24px;
        background: #F0F1F5;
        .el-form {
          padding-top: 22px;
          .el-form-item {
            // display: flex;
            // :deep(.el-form-item__label){
            //   width: 100px;
            // }
          }
        }
        .chat-header-btn-group {
          .el-button {
            cursor: pointer;
            padding: 8px 24px;
            border-radius: 18px;
            &.search-btn {
              color: #FFF;
              border: 1px solid #FF6767;
              background: linear-gradient( 307deg, #FF6767 0%, #FFA4A4 100%);
            }
            &.clear-btn {
              background: #fff;
              color: #FF6767;
            }
            &:hover {
              opacity: 0.8;
            }
            &:active, &:focus {
              opacity: 0.6;
            }
          }
        }
      }
    }
    .chat-body {
      display: flex;
      height: calc(100% - 60px);
      &.manager {
        height: calc(100vh - 174px);
      }
      .servicer-list {
        // width: 320px;
        flex: 1;
        height: 100%;
        border-right: 1px solid #ddd;
        .title {
          padding: 24px;
          font-size: 18px;
        }
        .servicer-ul {
          height: calc(100% - 72px);
          overflow-y: auto;
          .servicer-item {
            display: flex;
            align-items: center;
            padding: 15px 24px;
            cursor: pointer;
            &.active {
              background: #F7F8FC;
            }
            &:hover {
              opacity: 0.4;
            }
          }
          .headimg {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin-right: 20px;
            
          }
          .name {
            flex: 1;
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
      .user-list {
        flex: 1;
        width: 460px;
        height: 100%;
        .user-scroll {
          height: calc(100% - 72px);
          overflow-y: auto;
        }
        .title {
          padding: 24px;
          font-size: 18px;
        }
        .user-item {
          display: flex;
          align-items: center;
          padding: 15px 20px;
          cursor: pointer;
          position: relative;
          &.active {
            background: #F7F8FC;
          }
          .headimg {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin-right: 15px;
            position: relative;
            &.unread::before {
              content: '';
              width: 14px;
              height: 14px;
              border-radius: 50px;
              background: #FF6A6A;
              position: absolute;
              top: 0;
              right: 0;
            }
          }
          .info {
            flex: 1;
            margin-right: 2px;
            .name {
              font-size: 18px;
              width: 120px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .content {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              color: #999;
              font-size: 16px;
              margin-top: 10px;
              .timeout {
                color: #F29016;
              }
            }
          }
          .time {
            color: #ccc;
            position: absolute;
            top: 22px;
            right: 12px;
          }
          .close-btn {
            display: none;
            position: absolute;
            bottom: 10px;
            right: 24px;
            font-size: 22px;
            z-index: 6;
          }
          &:hover {
            .close-btn {
              display: block;
            }
          }
        }
      }
      .chat-box {
        flex: 2;
        height: 100%;
        background: #F5F6FA;
        .title {
          height: 70px;
          display: flex;
          padding: 0 24px;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #DDDDDD;
        }
        .chat-content {
          height: calc(100% - 250px);
          overflow-y: auto;
          .chat-item {
            display: flex;
            align-items: center;
            padding: 15px 24px;
            .chat-headimg {
              width: 60px;
              height: 60px;
              border-radius: 50%;
              margin-right: 20px;
             }
            .chat-content-text {
              display: flex;
              max-width: 60%;
              padding: 14px 24px;
              font-size: 22px;
              // min-height: 60px;
              // line-height: 60px;
              border-radius: 12px;
              align-items: center;
              word-break: break-word;
              justify-content: center;
            }
            &.sysUser-chat-item {
              transform: scaleX(-1);
              .chat-headimg {
                transform: scaleX(-1);
              }
              .chat-content-text {
                color: #fff;
                transform: scaleX(-1);
                background: #FF7B7B;
              }
            }
            &.user-chat-item {
              
              justify-content: flex-start;
              .chat-content-text {
                color: #333;
                
                background: #fff;
              }
            }
          }
          .chat-tips {
            color: #999;
            padding: 8px 14px;
            text-align: center;
            margin-bottom: 10px;
          }
          .more-record {
            padding: 15px 0;
            text-align: center;
          }
        }
        .chat-input {
          height: 180px;
          padding: 24px;
          position: relative;
          box-sizing: border-box;
          border-top: 1px solid #DDDDDD;
          .el-textarea {
            width: 100%;
            height: 100%;
            :deep(textarea) {
              width: 100%;
              height: 100%;
              border: none;
              resize: none;
              font-size: 20px;
              background: transparent;
              font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
            }
          }
          .emoji {
            position: absolute;
            top: 8px;
            left: 8px;
            .icon {
              width: 24px;
              height: 24px;
              &:active {
                opacity: 0.8;
              }
            }
          }
          .el-button {
            position: absolute;
            right: 24px;
            bottom: 24px;
            background: #FF6A6A;
            border-radius: 10px;
            color: #fff;
            border: 1px solid #FF6A6A;
            font-size: 18px;
            &.is-disabled {
              opacity: 0.4;
            }
          }
        }
      }
      .chat-accept {
        flex: 1;
        width: 460px;
        height: 100%;
        .title {
          padding: 24px;
          font-size: 18px;
        }
        .accept-list {
          height: calc(100% - 72px);
          overflow-y: auto;
          .accept-item {
            display: flex;
            padding: 15px 24px;
            position: relative;
            align-items: center;
            &.active {
              background: #F7F8FC;
            }
            .headimg {
              width: 60px;
              height: 60px;
              border-radius: 50%;
              margin-right: 20px;
            }
            .info {
              flex: 1;
              margin-right: 40px;
            }
            .name {
              font-size: 18px;
              font-weight: 500;
              width: 120px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .content {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              color: #999;
              font-size: 16px;
              margin-top: 10px;
            }
            .time {
              color: #ccc;
              position: absolute;
              top: 14px;
              right: 24px;
            }
            .accept-btn {
              position: absolute;
              right: 24px;
              bottom: 16px;
              .btn {
                cursor: pointer;
                padding: 8px 24px;
                color: #FFF;
                border-radius: 18px;
                background: linear-gradient( 307deg, #FF6767 0%, #FFA4A4 100%);
                &:hover {
                  opacity: 0.8;
                }
                &:active, &:focus {
                  opacity: 0.6;
                }
              }
            }
          }
          .loading-text {
            padding: 10px 0;
            font-size: 16px;
            text-align: center;
          }
        }
      }
    }
  }
}
.emoji-list {
  width: 440px;
  height: 200px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  .emoji-item {
    width: 35px;
    height: 35px;
    margin: 8px 6px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    &:active, &:focus {
      opacity: 0.6;
    }
  }
}
</style>