// src/api/chat
import request from './request';
import { urlConfig } from './urlconfig';
import qs from 'qs';

// 客服人员登陆接口-pc端
export function login(data) {
  return request({
    url: urlConfig.CHAT_URL + '/userLogin/login',
    method: 'post',
    data: qs.stringify(data)
  });
}

// 获取验证码接口
/*
export function getCode(APPID, phoneNum) {
  return request({
    url: urlConfig.BASE_URL + `/customerVerifyCode/send/${APPID}/${phoneNum}`,
    method: 'GET',
  });
}
*/

// 管理员查看客服聊天记录的接口 - 管理员端
export function queryAdviserUser(params) {
  return request({
    url: urlConfig.CHAT_URL + '/admin/queryAdviserUser',
    method: 'POST',
    params
  });
}

// 查询会话列表
/*
export function queryChatList(params) {
  return request({
    url: urlConfig.CUSTOMER_URL + `/chats/list`,
    method: 'GET',
    params
  });
}
*/
// 点击会话列表的用户,查询聊天记录 -> 共用接口
export function queryChatRecord(params) {
  return request({
    url: urlConfig.CUSTOMER_URL + `/chatContent/list`,
    method: 'GET',
    params
  });
}

// 查询当前有效会话的列表
/*
export function queryCurrentChat(params) {
  return request({
    url: urlConfig.CUSTOMER_URL + `/chats/cxdqhh`,
    method: 'GET',
    params
  });
}
*/
// 查询已结束会话列表 - 共用接口
// 用于查询已结束会话列表
export function queryChat4Time(params) {
  return request({
    url: urlConfig.CUSTOMER_URL + `/chats/list4Time`,
    method: 'GET',
    params
  });
}
// 查询会话数量

/*
 * PC端 废弃  用于APP端
 *
export function queryChatCount(params) {
  return request({
    url: urlConfig.CUSTOMER_URL + `/chats/hhsz`,
    method: 'GET',
    params
  });
}

export function querytoBeAcceptCount(params) {
  return request({
    url: urlConfig.CUSTOMER_URL + `/chats/hhsz2`,
    method: 'GET',
    params
  });
}
*/
// 接待；客服点击接待后，会话状态变为会话中- 客服端
export function servicerReception(data) {
  return request({
    url: urlConfig.CUSTOMER_URL + `/chats/jiedai`,
    method: 'POST',
    data: data
  });
}

//改变消息状态为已读；场景：用户发送消息，在用户列表中会显示出红圆点，客服点击就会调用此接口   ---> 客服端
export function chatsRead(data) {
  return request({
    url: urlConfig.CUSTOMER_URL + `/chats/qbyd`,
    method: 'POST',
    data: data
  });
}

//结束会话（仅发送提示，非真正结束），点击后将提示用户，会话会在3分钟后断开 -> 客服端
export function chatsBreak(data) {
  return request({
    url: urlConfig.CUSTOMER_URL + `/chats/tsdk`,
    method: 'POST',
    data: data
  });
}

// 客服设置某会话结束（真正结束）；场景：用户超时未回复后并在3分钟内没有回复消息 - 客服端
export function chatsOver(data){
  return request({
    url: urlConfig.CUSTOMER_URL + `/chats/hhjs`,
    method: 'POST',
    data: data
  });
}


// 用于查询当前会话列表的用户 - 共用接口
export function chatsSession(params){
  return request({
    url: urlConfig.CUSTOMER_URL + `/chats/list4Jxz`,
    method: 'GET',
    params: params
  });
}

// 待接受列表 - 客服端
export function chatsWaitAccept(params){
  return request({
    url: urlConfig.CUSTOMER_URL + `/chats/list4Djs`,
    method: 'GET',
    params: params
  });
}